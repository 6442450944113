<template>
  <b-modal
    id="associate-uncertainties-requirements-modal"
    :title="`Associate Issues with ${requirement.properties.display_id || ''}`"
    size="xl"
    ok-title="Allocate Issues"
    @shown="modalShown"
    @ok="AssociateUncertainties"
  >
    <div>
      <b-button variant="flat-success" @click="$bvModal.show('add-issue-modal')">
        <feather-icon icon="PlusIcon" /> Add Issue
      </b-button>
      <hr>
      <new-associator
        v-model="associated_issues"
        :items="issues"
        group="requirement"
        associate-title="Issues"
        :disassociate-title="`Issues Associated with ${requirement.properties.display_id}`"
      >
        <template v-slot:default="{item}">
          <div style="display: inline-block">
            <h6>{{ item.name }}</h6>
            <p v-html="item.description" />
          </div>
          <small style="float: right">
            <abbr :title="item.created">Issue Raised {{ item.created | diffForHumans }}</abbr> <br>
            <abbr v-if="item.updated" :title="item.updated">Issue Updated {{ item.updated | diffForHumans }}</abbr>
          </small>
          <div>
            <strong>Classification: </strong> {{ item.classification }} | <strong>Severity: </strong> {{ item.severity }} | <strong>Status: </strong> {{ item.status }}
          </div>
        </template>
      </new-associator>
      <add-issue-modal @issue-added="issue_id => associated_issues.push(issue_id)" />
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NewAssociator from '@/components/Forms/NewAssociator.vue'
import AddIssueModal from '@/components/Issues/Modals/IssueAdd.vue'

export default {
  name: 'AllocateIssues',
  components: {
    NewAssociator,
    AddIssueModal,
  },
  data() {
    return {
      associated_issues: [],
      initial_issues: [],
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
    }),
    ...mapState('issues', ['issues']),
    requirement() { return this.selected_requirement },
  },
  mounted() {
    this.$store.dispatch('issues/getIssues')
  },
  methods: {
    ...mapActions({
      associateUncertainties_vuex: 'requirements/associateUncertainties',
    }),
    AssociateUncertainties() {
      this.associateUncertainties_vuex({
        requirementId: this.requirement.properties.id,
        initial_uncertainties: this.initial_issues,
        final_uncertainties: this.associated_issues,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.associated_issues.length} Issues Associated`,
            icon: 'CheckIcon',
            text: `${this.associated_issues.map(({ id }) => id)}`,
            variant: 'success',
          },
        })
        this.$bvModal.hide('associate-uncertainties-requirements-modal')
      })
    },
    modalShown() {
      const initIssues = this.requirement.uncertainties.map(({ id }) => id)
      this.associated_issues = initIssues
      this.initial_issues = initIssues
    },
  },
}
</script>

<style>
.dark-layout .list-group-item-secondary h6{
    color: var(--dark);
}
</style>
