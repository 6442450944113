<template>
  <b-modal
    id="add-issue-modal"
    title="Raise Issue"
    size="lg"
    @show="onShow"
    @ok="submitFunction"
    @cancel="clearForm"
  >
    <b-form>
      <div class="mb-75">
        <label for="add_issue_rich_1">Issue</label>
        <tip-tap-editor
          id="add_issue_rich_1"
          v-model="name"
          placeholder="Briefly describe the Issue..."
          :allow-image-upload="false"
          min-height="6"
          max-height="6"
        />
      </div>

      <div class="mb-75">
        <label for="add_issue_select_1">Classification</label>
        <b-form-select
          id="add_issue_select_1"
          v-model="classification"
          :options="issueClassifications"
        />
      </div>

      <div class="mb-75">
        <label for="add_issue_select_2">Severity</label>
        <b-form-select
          id="add_issue_select_2"
          v-model="severity"
          :options="issueSeverities"
        />
      </div>

      <div class="mb-75">
        <label for="add_issue_select_3">Status</label>
        <b-form-select
          id="add_issue_select_3"
          v-model="status"
          :options="issueStatuses"
        />
      </div>

      <div class="mb-75">
        <label for="add_issue_rich_2">Additional Details</label>
        <tip-tap-editor
          id="add_issue_rich_2"
          v-model="description"
          placeholder="Give some more context to the Issue if required..."
          :allow-image-upload="false"
          min-height="8"
          max-height="8"
        />
      </div>
    </b-form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>

      <b-button variant="success" :disabled="loading_status" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Loading...
        </span>

        <span v-else>
          Raise Issue
        </span>
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
  },
  data: () => ({
    name: '',
    description: '',
    classification: '',
    severity: '',
    status: '',
    loading_status: false,
  }),
  computed: {
    ...mapGetters({
      issueClassifications: 'constants/issueClassifications',
      issueSeverities: 'constants/issueSeverities',
      issueStatuses: 'constants/issueStatuses',
    }),
  },
  methods: {
    onShow() {
      this.severity = this.$store.state.constants.defaultIssueSeverity.id
      this.classification = this.$store.state.constants.defaultIssueClassification.id
      this.status = this.$store.state.constants.defaultIssueStatus.id
    },
    submitFunction() {
      this.loading_status = true

      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        description: this.description,
        classification: this.classification,
        severity: this.severity,
        status: this.status,
      }
      this.$http.post('/api/v2/issues', payload)
        .then(({ data }) => {
          this.$store.dispatch('issues/getIssues')
          this.$bvModal.hide('add-issue-modal')
          this.clearForm()
          this.$emit('issue-added', data.id)
        })
        .catch(e => console.error(e))
    },
    clearForm() {
      this.name = ''
      this.description = ''
      this.classification = ''
      this.severity = ''
      this.status = ''
      this.loading_status = false
    },
  },
}
</script>
