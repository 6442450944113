<template>
  <b-list-group-item>
    <p class="font-small-3 mt-0 pt-0">
      <span class="mr-50">Classification:</span>
      <span class="font-weight-bolder mr-50">{{ issue.classification }}</span>|
      <span class="mr-50">Severity:</span>
      <span class="font-weight-bolder mr-50">{{ issue.severity }}</span>|
      <span class="mr-50">Status:</span>
      <span class="font-weight-bolder">{{ issue.status }}</span>
    </p>
    <p v-html="issue.name" />
    <b-button class="p-25" variant="flat-primary" @click="routeToIssue">
      <feather-icon icon="ExternalLinkIcon" /> Open Issue
    </b-button>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'IssueListGroupItem',
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    routeToIssue() {
      this.$router.push({
        name: 'issue_details',
        params: { id: this.issue.id },
      })
    },
  },
}
</script>
