<template>
  <b-form>
    <b-row>
      <b-col>
        <associator
          v-model="left_items"
          :group="group"
          :selected="left_selected"
          :title="associateTitle"
          @selected="right_selected = []"
          @clear-selected="clearSelected"
        >
          <template v-slot:default="{item}">
            <slot :item="item" />
          </template>
        </associator>
      </b-col>
      <b-col cols="1" style="position: relative">
        <b-button-group style="position: absolute; top:50%">
          <b-button
            v-if="right_selected.length"
            v-b-popover.hover.top="`Disassociate ${right_selected.length}`"
            variant="flat-danger"
            style="width: 100%"
            @click="disassociate"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </b-button>
          <b-button
            v-if="left_selected.length"
            v-b-popover.hover.top="`Associate ${left_selected.length}`"
            variant="flat-success"
            style="width: 100%"
            @click="associate"
          >
            <feather-icon icon="ArrowRightIcon" size="24" />
          </b-button>
        </b-button-group>
      </b-col>
      <b-col>
        <associator
          v-model="right_items"
          :group="group"
          :selected="right_selected"
          :title="disassociateTitle"
          @selected="left_selected = []"
          @clear-selected="clearSelected"
        >
          <template v-slot:default="{item}">
            <slot :item="item" />
          </template>
        </associator>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import NewAssociatorListGroup from '@/components/Forms/NewAssociatorListGroup.vue'

export default {
  name: 'NewAssociator',
  components: {
    Associator: NewAssociatorListGroup,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    idField: {
      type: String,
      default: 'id',
    },
    group: {
      type: String,
      required: true,
    },
    associateTitle: {
      type: String,
      default: 'Items',
    },
    disassociateTitle: {
      type: String,
      default: 'Items',
    },
  },
  data() {
    return {
      left_selected: [],
      right_selected: [],
    }
  },
  computed: {
    left_items: {
      set(i) { console.log(i) },
      get() {
        return this.items.filter(item => !this.value.includes(item[this.idField]))
      },
    },
    right_items: {
      set(i) {
        this.$emit('input', i.map(item => item[this.idField]))
      },
      get() {
        return this.items.filter(item => this.value.includes(item[this.idField]))
      },
    },
  },
  methods: {
    clearSelected() {
      this.left_selected = []
      this.right_selected = []
    },
    associate() {
      this.$emit('input', [...this.value, ...this.left_selected])
      this.clearSelected()
    },
    disassociate() {
      this.$emit('input', this.value.filter(item => !this.right_selected.includes(item)))
      this.clearSelected()
    },
  },
}
</script>

<style scoped>

</style>
