<template>
  <div>
    <drop-section :drag-event="drag_event" />

    <!-- Loading overlay -->
    <b-overlay
      v-if="loadingRequirements.state"
      show
      class="mt-4"
    >
      <template v-slot:overlay>
        <div class="my-3 animate-pulse">
          <b-spinner class="mr-2" />
          <span class="font-large-1">{{ loadingRequirements.text }}</span>
        </div>
      </template>
    </b-overlay>
    <!-- ./Loading overlay -->

    <!-- Requirements table -->
    <b-table-simple
      v-else
      :key="table_key"
      sticky-header="80vh"
      hover
      responsive
    >
      <b-thead>
        <b-tr ref="table_header">
          <b-th
            v-for="(column, column_index) in columns"
            :key="`header-column-${column_index}`"
            class="text-nowrap"
            :class="`${['section', 'display_id', 'priority', 'status'].includes(column) ? 'text-center' : ''}`"
            :style="setColumnWidth(column)"
            @click="sortTable(column)"
          >
            {{ column === 'object_text' ? 'requirement' : column.replaceAll("_", " ") }}
          </b-th>
        </b-tr>
      </b-thead>

      <draggable
        v-for="(specification, specification_index) in layout"
        :key="`table-body-${specification_index}`"
        tag="b-tbody"
        :disabled="true"
        @end="requirementMoved"
      >
        <!--SPECIFICATION HEADER ROW -->
        <!--<b-tr v-if="!$store.state.requirementsTableLegacy.specification" class="specification-header">-->
        <!--  <b-th-->
        <!--    :colspan="columns.length"-->
        <!--    :style="{-->
        <!--      position: 'sticky', 'top': table_header_row_height, zIndex: 1-->
        <!--    }"-->
        <!--  >-->
        <!--    <span class="font-small-3">Specification :</span>-->
        <!--    <span class="font-weight-bolder font-medium-2">-->
        <!--      {{ specification.title }}-->
        <!--    </span>-->
        <!--  </b-th>-->
        <!--</b-tr>-->
        <b-tr v-if="!Object.keys(specification.requirements).length">
          <b-th :colspan="columns.length" class="cursor-default">
            <div style="text-align: center">
              <span class="font-small-4 text-muted">
                Specification
                <span class="font-weight-bolder text-primary mx-25">{{ specification.title }}</span>
                has no requirements
              </span>
            </div>
          </b-th>
        </b-tr>
        <!-- REQUIREMENTS -->
        <b-tr
          v-for="(r, requirement_index) in tableData"
          :id="`requirement-${r.id}`"
          :key="`requirement-row-${requirement_index}-body-${specification_index}-${r.id}-${tableData.length}`"
          :class="rowClasses(r, requirement_index)"
          :data-id="r.id"
          :data-display-id="r.display_id"
          :data-section="r.section"
          :data-specification="specification"
          @click="evt => rowClicked(evt, r.id)"
          @click.ctrl="rowCtrlClicked(r)"
          @click.meta="rowCtrlClicked(r)"
          @contextmenu.prevent="evt => openContext(evt, r.id)"
        >
          <b-td
            v-for="(column, column_index) in columns"
            :key="`requirement-column-${column_index}-row-${requirement_index}-body-${column_index}-${r.id}`"
            :class="`${cellClass(r, column)} ${(edit_cell && edit_cell.row === r.id && edit_cell.column === column) ? 'p-25 m-0' : ''}`"
            @dblclick="startEditMode(r.id, column, r[column])"
          >
            <div
              v-if="edit_cell && edit_cell.row === r.id && edit_cell.column === column"
            >
              <!-- Save buttons -->
              <b-button-group class="mb-25">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  @click="updateRequirementCell(r.id, column, edit_cell.value)"
                >
                  Save changes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  @click="exitEditMode"
                >
                  Discard changes
                </b-button>
              </b-button-group>

              <!-- Rich text fields -->
              <tip-tap-editor
                v-if="isRichTextFields(column)"
                id="requirementInlineEdit"
                v-model="edit_cell.value"
                placeholder="Compose a Requirement..."
                full-toolbar
              />

              <!-- Select option fields -->
              <b-form-select
                v-else-if="isSelect(column)"
                v-model="edit_cell.value"
                :options="editCellOptions(column)"
              />

              <!-- Simple text fields -->
              <b-form-input
                v-else
                v-model="edit_cell.value"
              />
            </div>

            <div
              v-else-if="isRichTextFields(column)"
              class="p-0 m-0"
              :style="`margin-left: ${calcIndent(r['section'])}rem !important;`"
              v-html="r[column]"
            />

            <!-- ***************** QUALIFICATION RECORDS ***************-->
            <list-group-qualification-records
              v-else-if="column === 'qualification_records'"
              label="Qualification Records"
              class="p-0 m-0"
              :entity-array="r.qualification_records"
              modal="update-qualification-record"
              :show-heading="false"
            />

            <!-- ***************** ISSUES ***************-->
            <display-list-group
              v-else-if="column === 'issues'"
              :items="r.issues"
              title-pre="Issues"
            >
              <!-- FIXME This modal does not show correct data, removing from production code until fixed.
              modal-id="associate-uncertainties-requirements-modal"
              button-hover-text="Associate Issues"
              -->
              <template v-slot:list-group="{ items: issues }">
                <issue-list-group-item
                  v-for="(issue, issue_index) in issues"
                  :key="`issue-${issue_index}`"
                  :issue="issue"
                />
              </template>
            </display-list-group>

            <!-- ********** BEHAVIOURS *********-->
            <display-list-group
              v-else-if="column === 'behaviours'"
              :items="r.behaviours.bns"
              title-pre="Behaviours"
            >
              <template v-slot:list-group="{ items }">
                <b-list-group-item
                  v-for="(behaviour, beh_index) in items"
                  :key="`behaviour-${beh_index}`"
                >
                  <ListGroupItemBN
                    :key="beh_index"
                    :bn="behaviour"
                  />
                </b-list-group-item>
              </template>
            </display-list-group>

            <!-- *********** BNS ************ -->
            <div v-else-if="column === 'bns'">
              <ul style="word-wrap:break-word">
                <li
                  v-for="(bn, bn_index) in r.bns"
                  :key="`bn-${bn_index}`"
                >
                  <div>{{ bn }}</div>
                </li>
              </ul>
            </div>

            <!-- ********** COMPONENTS *********-->
            <display-list-group
              v-else-if="column === 'components'"
              :items="r.components"
              title-pre="Components"
            >
              <template v-slot:list-group="{ items }">
                <component-list-group-item
                  v-for="(component, component_index) in items"
                  :key="`component-${component_index}`"
                  :component="component"
                />
              </template>
            </display-list-group>

            <!-- ********** OBJECTIVES *********-->
            <display-list-group
              v-else-if="column === 'objectives'"
              :items="r.objectives"
              title-pre="Objectives"
            >
              <template v-slot:list-group="{ items }">
                <component-list-group-item
                  v-for="(objective, objective_index) in items"
                  :key="`objectives-${objective_index}`"
                  :component="objective"
                />
              </template>
            </display-list-group>

            <!-- ******** TESTS *********-->
            <display-list-group
              v-else-if="column === 'tests'"
              :items="r.tests"
              title-pre="Tests"
              modal-id="associate-tests-requirements-modal"
              button-hover-text="Associate Tests"
            >
              <template v-slot:list-group="{ items }">
                <test-list-group-item
                  v-for="(test, test_index) in items"
                  :key="`test-${test_index}`"
                  :test-object="test"
                />
              </template>
            </display-list-group>

            <!-- ******** NOTES *********-->
            <list-notes v-else-if="column === 'notes'" :all-notes="r.notes" read-only />

            <!-- ********** TRACES ******** -->
            <list-group-requirement-trace
              v-else-if="column === 'trace'"
              label="backward trace"
              :items="r.trace"
              modal-id="backward-trace-requirement-modal"
              :show-heading="false"
            />

            <!-- ********** COVERAGE ******** -->
            <list-group-requirement-trace
              v-else-if="column === 'coverage'"
              is-forward-trace
              label="forward trace"
              :items="r.coverage"
              modal-id="forward-trace-requirement-modal"
              :show-heading="false"
            />

            <!-- ********** SECTION / DISPLAY_ID / PRIORITY ******** -->
            <div
              v-else-if="column === 'section' || column === 'display_id' || column === 'priority'"
              class="d-inline-flex justify-content-center w-100"
            >
              <div class="text-nowrap font-small-3">
                {{ r[column] }}
              </div>
            </div>

            <!-- ********** COMPLIANCE ******** -->
            <div v-else-if="column === 'compliance'">
              <b-row v-for="(cr, index) in r.compliance" :key="index">
                <small>{{ cr.component }} - {{ cr.compliance }}</small>
              </b-row>
            </div>

            <!-- ********** PARSED INDICATOR ******** -->
            <div v-else-if="column === 'parsed_on'">
              <div class="font-small-3">
                <span v-if="r[column]">
                  {{ parsedDate(r[column]) }}
                </span>
                <span v-else>
                  Not parsed
                </span>
              </div>
            </div>

            <!-- ********** EVERYTHING ELSE ******** -->
            <div v-else>
              {{ r[column] }}
            </div>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
    <!-- ./Requirements table -->

    <!-- Quick add requirement -->
    <div id="requirementTableQuickAdd">
      <b-card v-if="adding_quick_requirement" no-body class="p-50">
        <tip-tap-editor
          ref="requirement_quick_add"
          v-model="quick_requirement_text"
          placeholder="Compose a Requirement..."
          @keydown.esc.native="closeQuickRequirement"
          @keydown.ctrl.enter.native.prevent="addQuickRequirement"
        />
        <div class="w-100 mt-50 d-inline-flex justify-content-start">
          <b-button
            :disabled="quick_requirement_text === '' || !apiStatus.LOADED || quickAddLoading"
            variant="flat-success"
            @click="addQuickRequirement"
          >
            Create Requirement
          </b-button>
          <b-button
            variant="flat-danger"
            @click="closeQuickRequirement"
          >
            Discard
          </b-button>
        </div>
      </b-card>
      <b-button
        v-if="layout && layout[0] && !adding_quick_requirement"
        :disabled="quickAddLoading"
        variant="flat-success"
        @click="openQuickRequirement"
      >
        Quick Add Requirement
      </b-button>
      <div v-hotkey="quick_add_requirement_keymap" />
    </div>
    <!-- ./Quick add requirement -->

    <RequirementTableContextMenu ref="menu" />
  </div>
</template>

<script>
import _ from 'lodash'
import { apiStatus } from '@/enums'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'

import ListNotes from '@/components/Notes/ListNotes.vue'
import ComponentListGroupItem from '@/components/Domain/Forms/ComponentListGroupItem.vue'
import DisplayListGroup from '@/views/RequirementsTableLegacy/components/DisplayListGroup.vue'
import IssueListGroupItem from '@/components/Issues/IssueListGroupItem.vue'
import ListGroupItemBN from '@/components/Forms/ListGroupItems/ListGroupItemBN.vue'
import ListGroupQualificationRecords from '@/components/Forms/ListGroups/ListGroupQualificationRecords.vue'
import ListGroupRequirementTrace from '@/components/Forms/ListGroups/ListGroupRequirementTrace.vue'
import RequirementTableContextMenu from '@/views/RequirementsTableLegacy/components/ContextMenu.vue'
import TestListGroupItem from '@/components/Tests/Forms/ListGroupItem.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import DropSection from '../modals/DropSection.vue'

export default {
  name: 'RequirementsTable',
  directives: {
    Ripple,
  },
  components: {
    ListNotes,
    ListGroupItemBN,
    TipTapEditor,
    draggable,
    DisplayListGroup,
    IssueListGroupItem,
    TestListGroupItem,
    ListGroupRequirementTrace,
    ListGroupQualificationRecords,
    ComponentListGroupItem,
    DropSection,
    RequirementTableContextMenu,
  },
  data() {
    return {
      apiStatus,
      table_key: 0,
      edit_cell: null,
      drag_event: null,
      table_header_row_height: '36px',
      adding_quick_requirement: false,
      quick_requirement_text: '',
      quickAddLoading: false,
      sort_by: { reverse: false, column: 'section' },
    }
  },
  computed: {
    ...mapState({
      tableData: state => {
        const convert = a => a?.section?.split('.').map(x => x.padStart(4, '0')).join('.')
        return _.orderBy(Object.values(state.requirementsTableLegacy.data), [convert])
      },
      layout: state => state.requirementsTableLegacy.layout,
      show_deleted: state => state.requirementsTableLegacy.show_deleted,
      table_status: state => state.requirementsTableLegacy.data_status,
      columns: state => state.requirementsTableLegacy.columns,
      snapshot_compare: state => state.requirementsTableLegacy.snapshot,
      mode: state => state.requirementsTableLegacy.mode,
      specification: state => state.requirementsTableLegacy.specification,
      selected_requirements: state => state.requirements.selected_requirements,
      selected_requirement: state => state.requirements.selected_requirement,
    }),
    ...mapGetters({
      requirementPriorities: 'constants/requirementPriorities',
      requirementStatuses: 'constants/requirementStatuses',
      requirementTypes: 'constants/requirementTypes',
      qualificationMethods: 'constants/qualificationMethods',
      securityClassifications: 'constants/securityClassifications',
    }),
    quick_add_requirement_keymap() {
      return {
        'alt+shift+n': this.openQuickRequirement,
        'alt+n': () => { this.$bvModal.show('add-requirement-modal') },
      }
    },
    loadingRequirements() {
      if (this.table_status !== apiStatus.LOADED) {
        return { state: true, text: 'Loading Requirements...' }
      }
      return { state: false }
    },
  },
  watch: {
    table_layout_status: {
      handler(newVal) {
        this.$nextTick(() => {
          const th = this.$refs.table_header
          if (th && newVal === apiStatus.LOADED) {
            this.table_header_row_height = `${th.$el.clientHeight}px`
          }
        })
      },
    },
  },
  updated() {
    this.$emit('table-updated', this.selected_requirements.map(({ id }) => id))
  },
  beforeDestroy() {
    this.$store.dispatch('requirements/clearRequirements')
    this.$store.dispatch('requirementsTableLegacy/clearRequirementsTable')
  },
  methods: {
    openQuickRequirement() {
      if (this.specification) {
        this.adding_quick_requirement = true
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight)
          this.$refs.requirement_quick_add.editor.commands.focus()
        })
      }
    },
    closeQuickRequirement() {
      if (this.adding_quick_requirement) {
        this.adding_quick_requirement = false
        this.quick_requirement_text = ''
      }
    },
    rowCtrlClicked({ id, deleted, display_id }) {
      if (this.selected_requirement.properties.id) {
        const { id, deleted, display_id } = this.selected_requirement.properties
        this.$store.commit('requirements/SET_SELECTED_REQUIREMENTS', [{ id, deleted, display_id }])
        this.$store.dispatch('requirements/clearSelectedRequirement')
      }
      if (this.selected_requirements.map(({ id }) => id).includes(id)) {
        this.$store.commit('requirements/SET_SELECTED_REQUIREMENTS', this.selected_requirements.filter(req => req.id !== id))
      } else {
        this.$store.commit('requirements/SET_SELECTED_REQUIREMENTS', [...this.selected_requirements, { id, deleted, display_id }])
      }
    },
    addQuickRequirement() {
      if (this.quickAddLoading) return
      this.quickAddLoading = true
      const payload = {
        spec_id: this.specification,
        object_text: this.quick_requirement_text.replace('<br></p>', '</p>'),
      }
      this.$store
        .dispatch('requirements/createRequirement', payload)
        .then(id => {
          this.quick_requirement_text = ''
          setTimeout(() => {
            this.$emit('go-to-requirement', id); window.scrollTo(0, document.body.scrollHeight)
          }, 300)
        })
        .finally(() => { this.quickAddLoading = false })
    },
    isRichTextFields(field) {
      return ['object_text'].includes(field)
    },
    calcIndent(section) {
      try {
        return (section.split('.').length - 1) * 1.5
      } catch (TypeError) {
        return 0
      }
    },
    isSelect(field) {
      return ['priority', 'status', 'classification', 'requirement_type', 'verification_method', 'validation_method'].includes(field)
    },
    updateRequirementCell(id, cell, value) {
      const requirement = {}
      requirement[cell] = value
      this.$store
        .dispatch('requirements/editRequirement', { id, requirement })
        .then(() => {
          this.$store.dispatch('requirements/selectRequirement', id)
          this.edit_cell = null
          this.$emit('go-to-requirement', id)
        })
    },
    startEditMode(row, column, value) {
      const disabledColumns = [
        // Core read-only Requirement properties
        'id',
        'display_id',
        'deleted',
        'created',
        'created_by',
        'updated',
        'updated_by',
        'spec_id',
        'model',

        // Section order columns
        'section',
        'level',
        'next_child_nr',
        'next_sibling_nr',

        // Various read-only columns
        'classification',
        'old_id',
        'copy_req',

        // Entity association columns
        'trace',
        'qualification_records',
        'coverage',
        'allocation',
        'issues',
        'tests',
        'behaviours',
        'bns',
        'components',
        'objectives',
        'notes',
        'comments',
      ]
      if (!disabledColumns.includes(column)) {
        this.edit_cell = { row, column }
        this.edit_cell.value = value
      }
    },
    requirementMoved(event) {
      if (event.newIndex !== event.oldIndex) {
        this.drag_event = Object(event)
        this.$nextTick(() => {
          // TODO Pass the event to the modal to capture the original index should a User cancel this action
          this.$bvModal.show('drop-section-modal')
        })
      }
    },
    exitEditMode() {
      this.edit_cell = null
    },
    rowClicked(evt, requirementId) {
      if (!evt.ctrlKey && !evt.metaKey) {
        this.$store.commit('requirements/SET_SELECTED_REQUIREMENTS', [])
        if (this.$route.query.focus !== requirementId) {
          this.$router.push({ query: { focus: requirementId } })
        }
      }
    },
    editCellOptions(column) {
      if (column === 'priority') return this.requirementPriorities
      if (column === 'verification_method') return this.qualificationMethods
      if (column === 'validation_method') return this.qualificationMethods
      if (column === 'requirement_type') return this.requirementTypes
      if (column === 'classification') return this.securityClassifications
      if (column === 'status') return this.requirementStatuses
      return []
    },
    sortTable(column) {
      if (this.sort_by.column === column) {
        this.$store.commit('requirementsTableLegacy/SET_SORT_BY', { column, reverse: !this.sort_by.reverse })
        this.$store.dispatch('requirementsTableLegacy/setLayout')
      } else {
        this.$store.commit('requirementsTableLegacy/SET_SORT_BY', { column, reverse: false })
      }
    },
    setColumnWidth(column) {
      if (['id', 'section', 'display_id', 'priority', 'status'].includes(column)) {
        return 'width: 1% !important;'
      }
      if (['trace', 'coverage', 'qualification_records'].includes(column)) {
        return 'width: 25% !important;'
      }
      return ''
    },
    cellClass(requirement, column) {
      let classes = ''
      if (['issues', 'notes', 'behaviours', 'bns', 'components', 'objectives', 'tests', 'trace', 'coverage', 'issues'].includes(column)) classes += ' p-0'
      return classes
    },
    rowClasses(reqObj, requirementIndex) {
      const nextRequirement = this.tableData[requirementIndex + 1]
      const classes = ['requirement-row']
      if (reqObj) {
        if (nextRequirement && reqObj.section && nextRequirement.section) {
          const level = reqObj.section.split('.').length
          const nextLevel = nextRequirement.section.split('.').length
          if (nextLevel > level) {
            classes.push('parent-row')
          }
        }
        if (reqObj.priority === 'Heading') {
          classes.push('row-heading')
        }
        if (this.selected_requirements.length && this.selected_requirements.map(({ id }) => id).includes(reqObj.id)) {
          classes.push('row-selected')
        } else if (reqObj.id === this.$store.state.requirements.selected_requirement.properties.id) {
          classes.push('row-selected')
        }
        if (reqObj.deleted && (reqObj.deleted === true || reqObj.deleted === 'True')) {
          classes.push('row-deleted')
          if (!this.show_deleted) {
            classes.push('row-hidden')
          }
        }
        if (this.mode === 'snapshot_compare') {
          if (this.snapshot_compare.new.includes(reqObj.id)) {
            classes.push('row-added')
          }
          if (this.snapshot_compare.modified.includes(reqObj.id)) {
            classes.push('row-modified')
          }
        }
      }
      return classes
    },
    async openContext(evt, requirementId) {
      this.$refs.menu.$refs.menu.close()
      if (requirementId !== this.selected_requirement.properties.id) {
        await this.$store.dispatch('requirements/selectRequirement', requirementId)
      }
      this.$refs.menu.$refs.menu.open(evt)
    },
    parsedDate(date) {
      const dateUtc = moment.utc(date)
      const localDate = moment(dateUtc).local()
      return localDate.format('LLLL')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables/_variables.scss';

th {
  border: 2px !important;
}

td {
  padding: 0.85rem 1rem;
}

.headingRow-1 {
    background: rgb(183, 183, 183) ;
    font-size: medium;
    font-weight: bolder;
    color: black;
}
.headingRow-2 {
    background: rgb(203, 203, 203);
    font-size: medium;
    font-weight: bold;
}
.headingRow-3 {
    background: rgb(223, 223, 223);
    font-size: medium;
    font-weight: bold;
}
.headingRow-4, .headingRow-5, .headingRow-6 {
    font-size: medium;
    font-weight: bold;
}

.specification-header {
  th {
    background: #fbca89 !important;
    cursor: default;
    z-index: 1000;
  }
  span {
    color: var(--dark) !important;
  }
}

.requirement-row {
  // Placeholder for generic row styling
}

.row-selected {
  background-image: linear-gradient(47deg,#7367f0,#9e95f5) !important;
  td {
    color: white !important;
    font-weight: bold !important;
  }
}

.row-heading {
  background-color: rgba(243, 242, 247, 1) !important;
  font-weight: bold;
  text-decoration-color: #455A64 !important;
  text-decoration-thickness: 0.1rem;
  td {
    color: #455A64;
  }
}

#requirementInlineEdit {
  background-color: rgba(#f3f2f7, 1) !important;
  border-radius: 0.357rem;
  color: var(--dark) !important;
}

.row-deleted {
  color: var(--danger) !important;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  padding: 0 !important;
}

.row-hidden {
  display: none !important;
}

.dark-layout {
  #requirementInlineEdit {
    background-color: rgba(#283046, 1) !important;
    color: var(--light) !important;
  }

  .row-heading {
    background-color: rgba(52, 61, 85, 0.5) !important;
    text-decoration-color: rgba(255, 255, 255, 0.5) !important;
    td {
      color: white !important;
    }
  }

  .table-secondary:hover {
    td {
      background: #c2c1c1;
    }
  }
  .table-secondary {
    td {
      color: var(--dark);
      font-weight: bold;
    }
  }
  .table-danger:hover {
    td {
      background: #ffb3b3;
    }
  }
  .table-danger {
    td {
      color: var(--dark);
    }
  }
  .table-info {
    td {
      color: var(--dark);
      font-weight: bold;
    }
  }
  .table-info:hover {
    td {
      background: lighten($info, 0.5%);
    }
  }
  .parent-row {
    font-weight: bolder;
  }
}
</style>

<style>
  .row-modified {
    background-color: rgba(245, 123, 47, 0.53);
    text-decoration-thickness: 2px;
    padding: 0 !important;
  }
  .row-added {
    background-color: rgba(103, 245, 103, 0.53);
    text-decoration-thickness: 2px;
    padding: 0 !important;
  }
  .row-deleted-color {
    background-color: var(--danger);
  }
</style>
