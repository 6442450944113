<template>
  <b-modal
    id="section-navigation-modal"
    title="Section Navigator"
    size="lg"
    ok-title="Go to Section"
    :ok-disabled="!selectedSectionHeader"
    ok-variant="success"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    @show="onShow"
    @ok="$emit('go-to-requirement', selectedSectionHeader)"
  >
    <p class="mb-1 font-small-3">
      Use this window to quickly navigate to a section within this specification.
    </p>
    <hr>

    <div v-if="sectionHeaders">
      <div v-if="sectionHeaders.length > 0">
        <b-form-input
          v-model="filterQuery"
          type="search"
          placeholder="Filter sections..."
        />
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          style="max-height: 70vh"
        >
          <b-table
            v-if="sectionHeaders.length"
            :fields="fields"
            :items="filterResults"
            hover
            selectable
            select-mode="single"
            thead-class="d-none"
            @row-selected="selectRequirement"
          >
            <template #cell(object_text)="{ item }">
              <p v-html="item.object_text" />
            </template>
          </b-table>
        </vue-perfect-scrollbar>
      </div>
      <div v-else>
        <p>
          <span class="text-warning">There are no section headings defined in this specification.</span>
          To define a section heading, set the 'Priority' of a requirement to 'Heading'.
        </p>
      </div>
    </div>
    <div v-else>
      <b-spinner /> Loading section headers...
    </div>
  </b-modal>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'SectionNavigationModal',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    specificationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sectionHeaders: [],
      selectedSectionHeader: null,
      filterQuery: '',
      fields: [
        { key: 'section', label: 'Section' },
        { key: 'object_text', label: 'Text' },
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 20,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    filterResults() {
      return this.sectionHeaders.filter(
        row => row.object_text.toLowerCase().includes(this.filterQuery.toLowerCase())
          || row.section.includes(this.filterQuery.toLowerCase()),
      )
    },
  },
  methods: {
    selectRequirement(row) {
      if (row[0]) {
        this.selectedSectionHeader = row[0].id
      }
    },
    onShow() {
      const params = { model: this.$store.state.model.id }
      if (this.specificationId) {
        this.$http
          .get(`/api/v2/specifications/${this.specificationId}/section_headers`, { params })
          .then(({ data }) => {
            this.sectionHeaders = data
          })
      }
    },
  },
}
</script>
