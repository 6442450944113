<template>
  <div>
    <b-modal
      id="decompose-functions-modal"
      title="Decompose Requirement Functions"
      size="lg"
      lazy
      @show="onShow"
    >
      <b-row>
        <b-col>
          <span class="mb-1 font-medium-1 text-primary font-weight-bold">
            {{ req.display_id }}
          </span>
          <p class="mx-1 select-text" v-html="req.object_text" />
        </b-col>
      </b-row>

      <!-- Functions -->
      <b-row class="mt-2">
        <b-col>
          <b-form-group>
            <h5 class="mb-1">
              Requirement Functions
            </h5>
            <div
              v-for="(fn, index) in req.functions"
              :id="index"
              :key="index"
              class="mb-1"
            >
              <b-row>
                <b-col>
                  <b-form-input
                    v-model="fn.name"
                    placeholder="Enter a name for the function (verb phrase)"
                  />
                </b-col>
                <b-col
                  cols="1"
                  class="pl-0"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="h-100"
                    size="sm"
                    @click="removeFunction(req.functions, index)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="repeatFunction(req.functions)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Function</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- ./Functions -->

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Dismiss
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating and Associating...
          </span>

          <span v-else>
            Create & Associate Functions
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    req: {
      id: '',
      display_id: '',
      object_text: '',
      functions: [],
      removedFunctions: [],
    },
    parsing: false,
    local_results: null,
    nextObjectId: 0,
    loading: false,
  }),
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
    }),
  },
  methods: {
    onShow() {
      console.log('Requirement: ', this.selected_requirement)
      this.req.id = this.selected_requirement.properties.id
      this.req.display_id = this.selected_requirement.properties.display_id
      this.req.object_text = this.selected_requirement.properties.object_text
      this.req.functions = this.selected_requirement.direct_components ? this.selected_requirement.direct_components.filter(c => c.cpt_labels
        && (c.cpt_labels.includes('Function') || c.cpt_labels.includes('Capability'))).map(c => ({ name: c.name, level: 1 })) : []
      this.parsing = true
      const params = { model: this.$store.state.model.id }

      // try to identify functions from the parse
      this.$http
        .get(`/api/v2/requirements/${this.selected_requirement.properties.id}/parse2`, { params })
        .then(({ data }) => {
          console.log('Data: ', data)
          // Don't open the rest of the modal if there was an exception in parsing
          if (data.parse_results) {
            console.log('Parse Results: ', data.parse_results)
            data.parse_results.actions.forEach(a => {
              let found = false
              this.req.functions.forEach(x => {
                if (x.name === a.function) {
                  x.level = 1
                  found = true
                }
              })
              if (!found) {
                this.req.functions.push({ name: a.function, level: 1 })
              }
            })
          }
          console.log('Data Structure: ', this.req)
        }).catch(e => {
          this.parsing = false
        })
    },
    submit() {
      this.loading = true
      this.req.model = this.$store.state.model.id
      this.loading = false
      this.$bvModal.hide('decompose-functions-modal')
      this.$http
        .post('/api/v2/requirements/associate_req_fns', this.req)
        .then(({ data }) => {
          this.loading = false
          this.$bvModal.hide('decompose-functions-modal')
        })
    },

    // -- Utility -- //
    repeatFunction() {
      this.req.functions.push({ name: '', level: 1 })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeFunction(index) {
      const rem = this.req.functions.splice(index, 1)
      console.log('Remove', rem)
      this.req.removedFunctions.push(...rem)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>
