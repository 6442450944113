<template>
  <b-form style="max-height: 70vh; overflow-y: auto">
    <h5 class="mb-1">{{ title }} - {{ value.length }}
      <span v-if="selected.length" style="float: right">
        {{ selected.length }} - Selected
        <b-button
          variant="flat-secondary"
          size="sm"
          @click="clearSelected"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </span>
    </h5>
    <b-form-input
      v-model="search_query"
      type="search"
      placeholder="Search..."
      @input="clearSelected"
    />
    <draggable
      :group="group"
      :value="value"
      tag="b-list-group"
      @input="val => $emit('input', val)"
      @start="clearSearch"
    >
      <b-list-group-item
        v-for="(item, item_index) in items"
        :key="`key-${group}-${item_index}`"
        :variant="selected.includes(item[fieldId]) ? 'secondary' : ''"
        @click="toggleItemSelection(item[fieldId])"
      >
        <slot :item="item" />
      </b-list-group-item>
    </draggable>
    <b-card v-if="value.length === 0">
      No Items in List
    </b-card>
    <b-card v-else-if="items.length === 0">
      No Items with <strong>{{ search_query }}</strong>
    </b-card>
  </b-form>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'NewAssociatorListGroup',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Items',
    },
    fieldId: {
      type: String,
      default: 'id',
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search_query: '',
    }
  },
  computed: {
    items() {
      return this.value.filter(obj => {
        let flag = false
        Object.values(obj).forEach(val => {
          if (!val) return
          if (String(val).toLowerCase().includes(this.search_query.toLowerCase())) {
            flag = true
          }
        })
        return flag
      })
    },
  },
  methods: {
    clearSearch() {
      this.search_query = ''
    },
    clearSelected() {
      this.$emit('clear-selected')
    },
    toggleItemSelection(item) {
      const selectedCopy = this.selected
      if (this.selected.includes(item)) {
        selectedCopy.splice(this.selected.indexOf(item), 1)
      } else {
        selectedCopy.push(item)
      }
      this.$emit('selected', selectedCopy)
    },
  },
}
</script>

<style scoped>

</style>
